* {
  box-sizing: border-box;
}

.pricePage {
  padding-top: 35px;
  text-align: center;
  margin: 0 auto;
  background-color: #e7f5ff;
}

.pricePageHeader {
  font-size: 50px;
  font-weight: 700;
  line-height: 65px;
  color: var(--primary-text);
  text-align: center;
}

.pricePageCards {
  max-width: 1360px;
  padding: 54px 10px 100px;
  display: grid;
  grid-template-columns: repeat(3, minmax(288px, 370px));
  justify-content: center;
  gap: 30px;
  margin-inline: auto;
  border-radius: 80px;
  position: relative;
}

.subscriptionPlan {
  padding-bottom: 54px;
  max-width: 370px;
  width: 100%;
  min-height: 680px;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 50px;
  background-color: var(--white);
  padding-top: 57px;
  background-repeat: no-repeat;
  background-position: center -43px;
  transition: all 0.3s;
  position: relative;
  box-shadow: 0px 9px 16px 0px rgba(189, 199, 218, 0.5);

  &:hover {
    transform: translateY(-5px);
  }
}

.inDevInfo {
  position: absolute;
  display: flex;
  justify-content: space-between;
  min-width: 159px;
  align-items: center;
  right: 7%;
  bottom: 30px;
  font-size: 16px;
  font-weight: 500;
  line-height: 19px;
  color: #5489ff;

  img {
    padding-top: 4px;
  }
}

.subscriptionPlanSecond {
  outline: 4px solid #41d97e;
}

.subscriptionPlanBg {
  margin-top: -103px;
  margin-bottom: 6px;
  z-index: 1000;
}

.subscriptionPlanDiscount {
  position: absolute;
  top: -22px;
  right: 22px;
  font-size: 16px;
  font-weight: 700;
  line-height: 20px;
  text-align: center;
  color: var(--white);
  border-radius: 10px;
  max-width: 102px;
  width: 100%;
  padding: 10px 5px;
  background-color: #41d97e;
  text-transform: uppercase;
}

.subscriptionPlanHeader {
  color: var(--white);
  font-size: 26px;
  font-weight: 700;
  line-height: normal;
  text-transform: uppercase;
  border-radius: 30px;
  border: 3px solid #fff;
  padding: 15px 32px 13px 32px;
  margin: 0;
  max-width: 270px;
  width: 100%;
  text-align: center;
  margin-bottom: 18px;
}

.subscriptionPlanFirst .subscriptionPlanHeader {
  background-image: linear-gradient(271.01deg, #c9d1f1 0.86%, #adbae0 99.14%),
    linear-gradient(0deg, #ffffff, #ffffff);
}

.subscriptionPlanSecond .subscriptionPlanHeader {
  background-image: linear-gradient(91.34deg, #ff7b4a 0.95%, #fec14a 98.93%),
    linear-gradient(0deg, #ffffff, #ffffff);
}

.subscriptionPlanThird .subscriptionPlanHeader {
  background-image: linear-gradient(91.34deg, #5489ff 0.95%, #bd89ff 98.93%),
    linear-gradient(0deg, #ffffff, #ffffff);
}

.subscriptionPlanPrice {
  margin: 0;
  color: var(--primary-text);
  font-size: 58px;
  font-weight: 700;
  line-height: 58px;
  letter-spacing: -1.16px;
}

.subscriptionPlanPriceOld {
  display: inline-block;
  font-size: 30px;
  font-weight: 700;
  line-height: 37px;
  position: relative;
  margin-right: 10px;
  margin-left: -60px;
  color: var(--primary-text);
}

.subscriptionPlanPriceOld::after {
  content: "";
  position: absolute;
  width: 54px;
  height: 2px;
  background-color: var(--primary-text);
  left: -2px;
  top: 50%;
}

.subscriptionPlanTerm {
  margin: 0;
  font-size: 20px;
  font-weight: 500;
  line-height: 25px;
  color: #7791b7;
  letter-spacing: -2%;
  margin-bottom: 24px;
}

.line {
  width: 100%;
  height: 1px;
  background-color: rgba(174, 194, 223, 0.4);
  margin-bottom: 21px;
}

.subscriptionPlanList {
  list-style: none;
  margin: 0;
  padding: 0;
  color: #7791b7;
  align-self: flex-start;
  padding-left: 30px;
  padding-right: 30px;
  padding-bottom: 25px;
  height: 100%;
  text-align: left;

  li {
    background-image: url("../assets/check.svg");
    background-repeat: no-repeat;
    background-position: left top 4px;
    padding-left: 30px;
    font-size: 18px;
    font-weight: 500;
    line-height: 22px;
    position: relative;

    &::marker {
      text-align: center !important;
    }
  }

  li + li {
    margin-top: 15px;
  }
}

.subscriptionPlanFirst .subscriptionPlanList li:nth-child(4),
.subscriptionPlanFirst .subscriptionPlanList li:nth-child(5),
.subscriptionPlanFirst .subscriptionPlanList li:nth-child(6),
.subscriptionPlanFirst .subscriptionPlanList li:nth-child(7),
.subscriptionPlanSecond .subscriptionPlanList li:nth-child(7) {
  background-image: url("../assets/cross-tariff.svg");
}

.subscriptionPlanThird .subscriptionPlanList li:nth-child(7)::after {
  content: url("../assets/in-dev-icon.svg");
  position: absolute;
  right: -4px;
  top: -6px;
}

.subscriptionPlanInfo {
  margin: 0;
  color: #5489ff;
  font-size: 14px;
  font-weight: 600;
  line-height: normal;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 7px;
  margin-bottom: 30px;

  img {
    width: 15px;
    height: 15px;
  }
}

.subscriptionPlanBtn {
  color: var(--white);
  font-size: 20px;
  font-weight: 600;
  line-height: 24px;
  text-decoration: none;
  background-color: #3e78fa;
  border: none;
  border-radius: 14px;
  padding: 13px 20px;
  min-width: 148px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  transition: all 0.3s;

  &:hover,
  &:active {
    background-color: var(--main-blue-hover);
  }
}

.pricePageBtn {
  width: 312px;
  padding: 14px 36px;
  border: 2px solid var(--main-blue);
  border-radius: 8px;
  color: var(--main-blue);
  background-color: var(--white);
  font-size: 1.25rem;
  font-weight: 600;
  line-height: 24px;
  display: inline-block;
  margin-bottom: 40px;
  transition: all 0.3s;
  cursor: pointer;
}

.pricePageBtn:hover,
.pricePageBtn:active {
  background-color: var(--main-blue-hover);
  color: var(--white);
  border-color: var(--main-blue-hover);
}

.pageContainer {
  max-width: 970px;
  margin: auto;
  padding-top: 150px;
}

.pageHeader {
  font-size: 2rem;
  font-weight: 600;
  text-align: center;
  color: var(--primary-text);
}

.pageAnnotation {
  padding-top: 3rem;
  padding-right: 2rem;
  font-size: 1.5rem;
  font-weight: 600;
  text-align: right;
  color: #718096;
}

.textBlock {
  padding-top: 8rem;
}

.elementBlock {
  padding-bottom: 2rem;
}

.elementHeader {
  font-size: 1.75rem;
  font-weight: 600;
  line-height: 2.25rem;
  color: var(--primary-text);
}

.elementText {
  font-size: 1.1rem;
  font-weight: 500;
  line-height: 2rem;
  color: var(--second-text);
}

.homePageButtonContainer {
  padding-top: 100px;
  display: flex;
  justify-content: center;
}

@media screen and (max-width: 1130px) {
  .pricePageCards {
    gap: 20px;
  }

  .subscriptionPlanList {
    padding-left: 20px;
    padding-right: 20px;
  }

  .subscriptionPlanFirst .subscriptionPlanBg {
    max-width: 66px;
    width: 100%;
    max-height: 67px;
    height: 100%;
  }

  .subscriptionPlanSecond .subscriptionPlanBg {
    max-width: 65px;
    width: 100%;
    max-height: 67px;
    height: 100%;
  }

  .subscriptionPlanThird .subscriptionPlanBg {
    max-width: 78px;
    width: 100%;
    max-height: 67px;
    height: 100%;
  }

  .subscriptionPlanFirst .subscriptionPlanBg {
    margin-top: -95px;
    margin-bottom: 0;
  }

  .subscriptionPlanSecond .subscriptionPlanBg {
    margin-top: -95px;
    margin-bottom: 0;
  }

  .subscriptionPlanThird .subscriptionPlanBg {
    margin-top: -95px;
    margin-bottom: 0;
  }

  .subscriptionPlanDiscount {
    top: -21px;
    right: 8px;
    max-width: 110px;
  }

  .subscriptionPlanThird .subscriptionPlanList li:nth-child(7)::after {
    right: -15px;
  }
}

@media screen and (max-width: 1024px) {
  .pricePageCards {
    border-radius: 0;
  }

  .subscriptionPlanHeader {
    max-width: 166px;
    font-size: 22px;
    padding: 11px 20px 8px 20px;
    line-height: 27px;
  }

  .subscriptionPlanDiscount {
    font-size: 16px;
  }

  .subscriptionPlanPrice {
    font-size: 40px;
    line-height: 50px;
  }

  .subscriptionPlanPriceOld {
    font-size: 32px;
  }

  .subscriptionPlanTerm {
    font-size: 16px;
    line-height: 20px;
  }

  .subscriptionPlanBtn {
    font-size: 18px;
    padding: 13px 20px;
    min-width: 142px;
  }
}

@media screen and (max-width: 1023px) {
  .pricePageCards {
    grid-template-columns: repeat(3, minmax(226px, 288px));
    gap: 15px;
  }

  .subscriptionPlanDiscount {
    top: -16px;
    right: 15px;
    font-size: 14px;
    max-width: 85px;
    padding: 4px 4px;
  }

  .subscriptionPlan {
    min-height: 650px;
  }

  .subscriptionPlanList {
    padding-right: 5px;

    li {
      font-size: 14px;
    }
  }

  .subscriptionPlanThird .subscriptionPlanList li:nth-child(7)::after {
    right: 10px;
    scale: 0.7;
  }
}

@media screen and (max-width: 860px) {
  .pricePageCards {
    grid-template-columns: repeat(1, minmax(350px, 363px));
    gap: 60px;
  }

  .subscriptionPlanHeader {
    max-width: 269px;
    font-size: 26px;
    padding: 12px 20px 10px 20px;
    line-height: 32px;
  }

  .subscriptionPlanDiscount {
    top: -18px;
    right: 8px;
    font-size: 18px;
    max-width: 123px;
    padding: 9px 5px;
  }

  .subscriptionPlanFirst .subscriptionPlanBg {
    max-width: none;
    width: auto;
    max-height: none;
    height: auto;
  }

  .subscriptionPlanSecond .subscriptionPlanBg {
    max-width: none;
    width: auto;
    max-height: none;
    height: auto;
  }

  .subscriptionPlanThird .subscriptionPlanBg {
    max-width: none;
    width: auto;
    max-height: none;
    height: auto;
  }

  .subscriptionPlanFirst .subscriptionPlanBg {
    margin-top: -103px;
    margin-bottom: 6px;
  }

  .subscriptionPlanSecond .subscriptionPlanBg {
    margin-top: -103px;
    margin-bottom: 6px;
  }

  .subscriptionPlanThird .subscriptionPlanBg {
    margin-top: -103px;
    margin-bottom: 6px;
  }

  .subscriptionPlanPrice {
    font-size: 58px;
    line-height: 72px;
  }

  .subscriptionPlanPriceOld {
    font-size: 40px;
  }

  .subscriptionPlanTerm {
    margin: 0;
    font-size: 20px;
    margin-bottom: 25px;
  }

  .subscriptionPlanList {
    padding-right: 20px;

    li {
      font-size: 18px;
    }
  }

  .subscriptionPlanBtn {
    font-size: 20px;
    min-width: 162px;
  }

  .inDevInfo {
    right: 10%;
  }
}

@media screen and (max-width: 730px) {
  .pricePageCards {
    padding-bottom: 73px;
    background-position:
      -280px -450px,
      -1000px 0;
  }

  .pricePageHeader {
    font-size: 2rem;
    line-height: 48px;
    margin-bottom: 30px;
    margin: 0;
  }

  .pricePageBtn {
    width: 361px;
    margin-bottom: 50px;
  }

  .inDevInfo {
    bottom: 15px;
  }
}

@media screen and (max-width: 560px) {
  .pricePageCards {
    padding: 30px 10px 30px;
    display: flex;
  }

  .subscriptionPlan {
    padding-bottom: 54px;
    max-width: 343px;
    width: 100%;
    padding-top: 57px;

    &:hover {
      transform: translateY(0);
    }
  }

  .pricePageHeader {
    font-size: 36px;
    line-height: 50px;
    margin-bottom: 0px;
    margin: 0;
  }

  .subscriptionPlanDiscount {
    top: -20px;
    right: 10px;
    font-size: 16px;
    max-width: 102px;
    padding: 10px 5px;
  }
}
