.skillsContainer {
  padding: 80px 20px 20px 20px;
  max-width: 1400px;
  margin-inline: auto;
  margin-bottom: 112px;
}

.skillsHeader {
  margin: 0;
  margin-bottom: 76px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 50px;
  font-weight: 700;
  line-height: 65px;
  text-align: center;
  color: var(--primary-text);
}

.itemsContainer {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(370px, 370px));
  gap: 30px;
  justify-content: center;
}

figure {
  margin: 0;
}

.skillsItem {
  border-radius: 70px;
  text-align: left;
  background-color: #e7f5ff;
  overflow: hidden;
  max-width: 373px;
}

.imageContainer {
  margin-bottom: 10px;
  display: flex;
  position: relative;
}

.skillsItem1 .imageContainer {
  &::after {
    content: url("../assets/Skills/skills-bg-1.svg");
    position: absolute;
    bottom: 0;
    right: 20px;
  }
}

.skillsItem2 .imageContainer {
  margin-bottom: 22px;

  &::after {
    content: url("../assets/Skills/skills-bg-2.svg");
    position: absolute;
    bottom: -3px;
    right: 18px;
  }
}

.skillsItem3 .imageContainer {
  margin-bottom: 22px;

  &::after {
    content: url("../assets/Skills/skills-bg-3.svg");
    position: absolute;
    bottom: 5px;
    left: 15px;
  }
}

.skillsItem1 .itemImage {
  max-width: 361px;
  width: 100%;
  mask-image: url("../assets/Skills/skills-mask-1.svg");
  mask-repeat: no-repeat;
  -webkit-mask-repeat: no-repeat;
  mask-size: 330px 318px;
  -webkit-mask-size: 330px 318px;
  transform: scaleX(-1);
  mask-position: 30px 45%;
  margin-top: -27px;
  margin-left: -2px;
}

.skillsItem2 .itemImage {
  mask-image: url("../assets/Skills/skills-mask-2.svg");
  mask-repeat: no-repeat;
  -webkit-mask-repeat: no-repeat;
  mask-size: 404px 355px;
  -webkit-mask-size: 404px 355px;
  mask-position: 0px 100%;
}

.skillsItem3 .itemImage {
  mask-image: url("../assets/Skills/skills-mask-3.svg");
  mask-repeat: no-repeat;
  -webkit-mask-repeat: no-repeat;
  mask-size: 366px 235px;
  -webkit-mask-size: 366px 235px;
  mask-position: 0px 100%;
}

.skillsItem2 .imageContainer,
.skillsItem3 .imageContainer {
  justify-content: flex-end;
}

.itemImage {
  display: block;
}

.itemContainer {
  padding-left: 40px;
  padding-right: 40px;
  padding-bottom: 30px;
}

.itemHeader {
  margin: 0;
  margin-bottom: 24px;
  font-size: 36px;
  font-weight: 700;
  line-height: 45px;
  color: var(--primary-text);
}

.itemText {
  margin: 0;
  font-size: 20px;
  font-weight: 500;
  line-height: 35px;
  color: #516f9a;
}

@media screen and (max-width: 1209px) {
  .skillsItem3 {
    grid-column: 1 / -1;
    justify-self: center;
    max-width: 373px;
  }

  .itemContainer {
    padding-left: 40px;
    padding-right: 40px;
    padding-bottom: 40px;
  }
}

@media screen and (max-width: 1024px) {
  .skillsHeaderContainer {
    display: flex;
    justify-content: center;
  }

  .skillsHeader {
    font-size: 36px;
    line-height: 50px;
    max-width: 17ch;
  }

  .itemHeader {
    margin-bottom: 24px;
    font-size: 30px;
    line-height: 37px;
  }
}

@media screen and (max-width: 768px) {
  .skillsContainer {
    padding: 30px 0;
  }

  .skillsHeader {
    margin-bottom: 35px;
  }

  .skillsItem {
    border-radius: 50px;
  }

  .itemContainer {
    padding-left: 38px;
    padding-right: 38px;
  }

  .itemsContainer {
    gap: 22px;
  }
}

@media screen and (max-width: 370px) {
  .skillsItem {
    max-width: 360px;
  }
}
