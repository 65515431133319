img {
  max-width: 100%;
}

.platformInfo {
  position: relative;
  padding: 85px 20px 130px;
  max-width: 1190px;
  margin: 0 auto;
  text-align: center;
}

.headerContainer {
  display: flex;
  justify-content: center;
}

.platformInfoHeader {
  font-size: 50px;
  font-weight: 700;
  line-height: 65px;
  color: var(--primary-text);
  text-align: center;
}

.container {
  padding-top: 30px;
  margin-bottom: 98px;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  justify-content: space-between;
  row-gap: 70px;
}

.stepNumber,
.stepTitle {
  margin: 0;
}

.stepNumber {
  font-size: 150px;
  font-weight: 800;
  line-height: 189px;
  text-align: left;
  color: #caaaff;
}

.stepTextBox {
  max-width: 570px;
}

.stepTextBox5 .stepNumber {
  line-height: 175px;
}

.stepTextBox2 .stepNumber {
  color: #aadb49;
}

.stepTextBox3 .stepNumber {
  color: #76d7fb;
}

.stepTextBox4 .stepNumber {
  color: #ff83bb;
}

.stepTextBox5 .stepNumber {
  color: #f9df6f;
}

.stepTitle {
  max-width: 16ch;
  font-size: 36px;
  font-weight: 700;
  line-height: 45px;
  text-align: left;
  color: var(--primary-text);
  margin-bottom: 24px;
}

.stepTextBox5 .stepTitle {
  margin-bottom: 28px;
}

.stepDescription {
  font-size: 20px;
  font-weight: 500;
  line-height: 35px;
  color: #516f9a;
  text-align: left;
  max-width: 30ch;
}

.stepTextBox1,
.stepTextBox3 {
  padding-top: 25px;
}

.stepTextBox2,
.stepTextBox4 {
  padding-top: 40px;
  justify-self: end;
}

.stepTextBox5 {
  padding-top: 0px;
}

.stepImgBox {
  position: relative;
  align-self: center;
  display: flex;
  width: 100%;
  max-width: 570px;

  &::before {
    position: absolute;
  }
}

.stepImg {
  border-radius: 70px;
  display: block;
}

.stepImgBox:nth-child(odd) {
  justify-content: flex-end;
}

.stepImgBox:nth-child(even) {
  justify-content: flex-start;
}

.stepImgBox1 {
  width: 100%;

  &::before {
    content: "";
    position: absolute;
    background-image: url("../assets/PlatformInfo/imageBg1.svg");
    width: 155px;
    height: 123px;
    top: 14%;
    right: 10%;
  }
}

.stepImgBox2 {
  &::before {
    content: url("../assets/PlatformInfo/imageBg2.svg");
    width: 383px;
    height: 145px;
    top: 15%;
    right: 23%;
  }
}

.stepImgBox3 {
  &::before {
    content: url("../assets/PlatformInfo/imageBg3.svg");
    width: 150px;
    height: 132px;
    bottom: 4%;
    right: 11%;
  }
}

.stepImgBox4 {
  &::before {
    content: url("../assets/PlatformInfo/imageBg4.svg");
    width: 256px;
    height: 185px;
    top: 8%;
    left: 10%;
  }
}

.stepImgBox5 {
  &::before {
    content: url("../assets/PlatformInfo/imageBg5.svg");
    width: 171px;
    height: 132px;
    top: 14%;
    right: 16%;
  }
}

.button {
  padding: 23px 20px;
  font-size: 22px;
  font-weight: 700;
  line-height: 24px;
  max-width: 200px;
  width: 100%;
  border-radius: 15px;
  transition: background-color 0.3s;
}

@media screen and (max-width: 1366px) {
  .platformInfo {
    padding: 10px 20px 70px;
  }
}

@media screen and (max-width: 1024px) {
  .platformInfoHeader {
    font-size: 36px;
    line-height: 50px;
  }

  .container {
    margin-bottom: 70px;
    row-gap: 30px;
  }

  .stepNumber {
    font-size: 120px;
    line-height: 150px;
  }

  .stepTitle {
    font-size: 32px;
    line-height: 38px;
  }

  .stepDescription {
    font-size: 18px;
    line-height: 32px;
  }

  .stepImgBox1 {
    width: 100%;

    &::before {
      background-size: cover;
      width: 104px;
      height: 82px;
      top: 17%;
      right: 16%;
    }
  }

  .stepImgBox2 {
    &::before {
      scale: 0.65;
      top: 12%;
      left: -5%;
    }
  }

  .stepImgBox3 {
    &::before {
      scale: 0.65;
      bottom: 2%;
      right: 6%;
    }
  }

  .stepImgBox4 {
    &::before {
      scale: 0.65;
      top: 5%;
      left: 5%;
    }
  }

  .stepImgBox5 {
    &::before {
      scale: 0.65;
      top: 12%;
      right: 12%;
    }
  }
}

@media screen and (max-width: 768px) {
  .platformInfo {
    padding: 10px 20px 70px;
  }
}

@media screen and (max-width: 767px) {
  .platformInfo {
    padding: 10px 10px 70px;
  }

  .platformInfoHeader {
    margin-bottom: 0px;
  }

  .container {
    padding-top: 0;
    margin-bottom: 50px;
    grid-template-columns: 1fr;
    row-gap: 10px;
    justify-items: center;
  }

  .stepNumber {
    font-size: 150px;
    line-height: 189px;
  }

  .stepTitle {
    font-size: 30px;
    line-height: 37px;
    max-width: 100%;
  }

  .stepDescription {
    font-size: 20px;
    line-height: 35px;
    max-width: 100%;
  }

  .stepTextBox2,
  .stepTextBox4 {
    padding-top: 0px;
    justify-self: center;
  }

  .stepTextBox1,
  .stepTextBox3 {
    padding-top: 0px;
  }

  .stepTextBox:nth-child(4) {
    grid-row: 3;
  }

  .stepTextBox:nth-child(8) {
    grid-row: 7;
  }

  .stepImgBox1 {
    width: 100%;

    &::before {
      top: 19%;
      right: 18%;
    }
  }

  .stepImgBox2 {
    &::before {
      top: 12%;
      left: 5%;
    }
  }

  .stepImgBox3 {
    &::before {
      bottom: 5%;
      right: 7%;
    }
  }

  .stepImgBox4 {
    &::before {
      top: 8%;
      left: 8%;
    }
  }

  .stepImgBox5 {
    &::before {
      top: 14%;
      right: 14%;
    }
  }

  .button {
    padding: 17px 20px 19px;
    font-size: 18px;
    max-width: 180px;
  }
}

@media screen and (max-width: 565px) {
  .platformInfoHeader {
    max-width: 15ch;
  }
}
