.platform {
  padding-top: 70px;
  padding-bottom: 66px;
  width: 100%;
  margin: 0 auto;
  margin-bottom: 130px;
  position: relative;
  height: calc(100vh - 80px);
}

.video {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  object-fit: cover;
  z-index: 0;
  opacity: 0.9;
}

.platformContainer {
  margin-inline: auto;
  max-width: 1210px;
  height: 100%;
  position: relative;
  z-index: 1;
  min-width: 0;
}

.info {
  padding: 0;
  padding-left: 20px;
  padding-right: 20px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  height: 100%;
}

.infoList {
  margin: 0;
  padding: 0;
  list-style-type: none;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;
}

.infoListItem {
  padding: 12px 24px 14px;
  font-size: 18px;
  line-height: 24px;
  font-weight: 700;
  color: var(--main-blue);
  background-color: var(--white);
  border-radius: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.infoContainer {
  display: flex;
  flex-direction: column;
  max-width: 1210px;
  height: 100%;
  justify-content: flex-start;
}

.btnContainer {
  align-self: flex-start;
  display: flex;
  gap: 30px;
}

.registrationButton {
  padding: 23px 20px;
  min-width: 202px;
  border-radius: 15px;
  font-size: 22px;
  font-weight: 700;
  line-height: 24px;
  transition: all 0.3s;
  display: flex;
  align-items: center;
  justify-content: center;
}

.infoBtn {
  padding: 23px 20px;
  min-width: 202px;
  border-radius: 15px;
  border: none;
  font-size: 22px;
  font-weight: 700;
  line-height: 24px;
  background-color: #c6e7ff;
  color: var(--main-blue);
  cursor: pointer;
  text-align: center;
  text-decoration: none;
  transition:
    background-color 0.3s,
    color 0.3s;
}

.infoBtn:hover,
.infoBtn:active {
  background-color: var(--main-blue-hover);
  color: var(--white);
}

@media screen and (max-width: 1200px) {
  .platformContainer {
    padding-left: 10px;
    padding-right: 10px;
  }
}

@media screen and (max-width: 1024px) {
  .platform {
    margin-bottom: 60px;
  }
}

@media screen and (max-width: 768px) {
  .registrationButton {
    width: 100%;
  }
}

@media screen and (max-width: 700px) {
  .platform {
    align-items: flex-start;
    padding-bottom: 40px;
  }

  .info {
    flex-direction: column;
    align-items: flex-start;
    gap: 40px;
  }

  .btnContainer {
    align-self: flex-start;
  }
}

@media screen and (max-width: 595px) {
  .platform {
    padding-top: 30px;
    padding-bottom: 20px;
    height: 85vh;
    height: 85svh;
  }

  .infoListItem {
    font-size: 15px;
    line-height: 24px;
    padding: 8px 16px;
  }

  .registrationButton,
  .infoBtn {
    padding: 18px 20px;
    font-size: 18px;
    line-height: 24px;
  }

  .btnContainer {
    flex-direction: column;
    gap: 20px;
  }
}

@media screen and (max-width: 480px) {
  .platform {
    padding-left: 0;
    padding-right: 0;
    margin-bottom: 37px;
  }

  .platformContainer {
    padding-left: 0px;
    padding-right: 0px;
  }

  .info {
    padding-left: 10px;
    padding-right: 10px;
  }

  .btnContainer {
    width: 100%;
    gap: 10px;
  }
}
