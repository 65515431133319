.trendsContainer {
  position: relative;
  max-width: 1210px;
  padding-left: 20px;
  padding-right: 20px;
  margin: auto;
}

.trends {
  background-image: url("../assets/Trends/trends-bg.svg");
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 70px;
  position: relative;
}

.trendsBlock {
  margin: 0 auto;
  padding: 70px 0px 74px 70px;
  display: flex;
  justify-content: space-between;
}

.trendsHeaderContainer {
  display: flex;
  align-items: center;
}

.trendsHeader {
  margin: 0;
  max-width: 13ch;
  text-align: left;
  font-size: 50px;
  font-weight: 700;
  line-height: 65px;
  color: var(--primary-text);
}

.trendItemsList {
  margin: 0;
  padding: 0;
  list-style-type: none;
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  width: 55%;
  padding-left: 30px;
}

.trendItemsListItem {
  padding: 13px 30px;
  color: var(--white);
  font-size: 30px;
  font-weight: 700;
  line-height: 37px;
  border-radius: 35px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-grow: 0;
}

.trendItemsListItem:nth-child(1) {
  background-color: #caaaff;
}

.trendItemsListItem:nth-child(2) {
  background-color: #aadb49;
}

.trendItemsListItem:nth-child(3) {
  background-color: #76d7fb;
}

.trendItemsListItem:nth-child(4) {
  background-color: #caaaff;
}

.trendItemsListItem:nth-child(5) {
  background-color: #ff83bb;
}

.trendItemsListItem:nth-child(6) {
  background-color: #aadb49;
}

@media screen and (max-width: 1024px) {
  .trendsBlock {
    padding: 75px 50px 100px;
  }

  .trendsHeader {
    font-size: 36px;
    line-height: 50px;
  }

  .trendItemsListItem {
    padding: 10px 20px;
    font-size: 24px;
    line-height: 30px;
  }
}

@media screen and (max-width: 880px) {
  .trendsBlock {
    padding: 75px 30px 100px;
    flex-direction: column;
    align-items: center;
    gap: 40px;
  }

  .trendsHeader {
    text-align: center;
  }

  .trendItemsList {
    padding-left: 0;
    justify-content: center;
    width: 100%;
    gap: 10px;
  }
}

@media screen and (max-width: 768px) {
  .trendsContainer {
    padding: 20px 10px 20px 10px;
  }

  .trendsBlock {
    padding: 57px 40px 100px;
  }

  .trendItem {
    max-width: 386px;
  }
}

@media screen and (max-width: 630px) {
  .trendsBlock {
    padding: 40px 10px 40px;
  }
}

@media screen and (max-width: 470px) {
  .trendsBlock {
    padding-bottom: 17px;
    margin-bottom: 25px;
  }

  .trendsContainer {
    padding-top: 29px;
  }

  .trendItem {
    max-width: 361px;
  }
}
