* {
  font-family: Gilroy;
  font-style: normal;
  font-size: 16px;
  line-height: 24px;
}

:root {
  --main-blue: #3e78fa;
  --main-blue-hover: #1c60f9;
  --white: #ffffff;
  --second-text: #525f7f;
  --primary-text: #153561;
  --tertiary-text: #8aa418;
}

body {
  margin: 0 auto;
  width: 100%;
}

.body {
  max-width: 1920px;
  width: 100%;
  margin: 0 auto;
}

.visually-hidden {
  position: absolute;
  width: 1px;
  height: 1px;
  margin: -1px;
  padding: 0;
  border: 0;
  white-space: nowrap;
  clip-path: inset(100%);
  clip: rect(0 0 0 0);
  overflow: hidden;
}

.hide {
  display: none;
}

.modalBackground {
  position: fixed;
  width: 100%;
  height: 100vh;
  background: rgba(122, 120, 120, 0.6);
  z-index: 10;
  top: 0;
  left: 0;
}

.modal__email {
  box-sizing: border-box;
  position: relative;
  max-width: 760px;
  border-radius: 1.2rem;
  background: var(--white);
  padding: 60px;
  padding-top: 54px;
  padding-bottom: 30px;
  top: 100px;
  margin: 0 auto;
  text-align: center;
  box-shadow: 0 0 12px -5px var(--second-text);
  z-index: 4;
}

.modal__sent {
  box-sizing: border-box;
  position: relative;
  max-width: 512px;
  border-radius: 2.5rem;
  background: var(--white);
  padding: 60px;
  padding-top: 60px;
  top: 100px;
  text-align: center;
  box-shadow: 0 0 12px -5px var(--second-text);
  z-index: 4;
  margin: 0 auto;
}

.modal__first {
  box-sizing: border-box;
  position: relative;
  max-width: 760px;
  border-radius: 2.5rem;
  background: var(--white);
  padding: 60px;
  padding-top: 55px;
  top: 100px;
  text-align: center;
  box-shadow: 0 0 12px -5px var(--second-text);
  z-index: 4;
  margin: 0 auto;
}

.modalCloseIcon {
  cursor: pointer;
  color: var(--main-blue-hover);
  right: 46px;
  top: 33px;
  font-size: 1.5rem;
  width: 32px;
  height: 32px;
  position: absolute;
}

.modalCloseIcon::after {
  content: "";
  position: absolute;
  height: 2px;
  width: 26px;
  background-color: var(--main-blue);
  transform: rotate(45deg);
}

.modalCloseIcon::before {
  content: "";
  position: absolute;
  height: 2px;
  width: 26px;
  background-color: var(--main-blue);
  transform: rotate(-45deg);
}

.modalHeader {
  letter-spacing: -1.04px;
  font-size: 3.25rem;
  line-height: 3.5rem;
  font-weight: 700;
  text-align: center;
  color: var(--primary-text);
  margin-bottom: 48px;
}

.modalTextInfo {
  text-align: center;
  color: var(--main-blue-hover);
  font-size: 1.75rem;
  font-weight: 700;
  margin-bottom: 44px;
}

.modalTextInfo__first {
  margin-bottom: 46px;
}

.modalText__email {
  color: var(--second-text);
}

.modalBold,
.modalText {
  font-size: 1.25rem;
  line-height: 2rem;
  letter-spacing: -0.2px;
  margin-bottom: 17px;
}

.modalBold {
  font-weight: 700;
  letter-spacing: 0px;
}

.modalBold__first {
  display: inline-block;
  padding-top: 5px;
  margin-bottom: 0;
  padding-bottom: 0px;
}

.modalText {
  font-weight: 500;
}

.modalButton {
  width: 100%;
  border-radius: 0.5rem;
  border: 2px solid var(--main-blue);
  color: var(--main-blue);
  background: var(--white);
  padding: 16px 10px 13px 10px;
  cursor: pointer;
  line-height: 1.2;
  font-size: 1.25rem;
  font-weight: 600;
}

.modalButton__first {
  width: 312px;
  margin-top: 23px;
}

.modalButton:hover {
  color: var(--main-blue-hover);
  border: 2px solid var(--main-blue-hover);
}

@media screen and (max-width: 1440px) {
  .modal {
    max-width: 637px;
    padding: 45px 34px;
  }

  .modal__email {
    max-width: 637px;
    padding: 45px 30px 10px;
  }

  .modalCloseIcon {
    right: 35px;
    top: 35px;
  }

  .modalHeader {
    font-size: 2rem;
    line-height: 2.5rem;
    letter-spacing: -0.6px;
    margin-bottom: 30px;
  }

  .modalTextInfo {
    font-size: 1.25rem;
    margin-bottom: 19px;
  }

  .modalBold,
  .modalText {
    font-size: 1rem;
    line-height: 1.7rem;
  }

  .modalBold {
    letter-spacing: -0.2px;
    margin-bottom: 0;
    display: inline-block;
  }

  .modalText {
    letter-spacing: -1%;
    line-height: 1.4rem;
    margin-bottom: 19px;
  }
}

@media screen and (max-width: 768px) {
  .modal {
    max-width: 557px;
    border-radius: 20px;
    padding: 40px 16px;
  }

  .modal__sent {
    max-width: 416px;
    padding: 40px 20px;
  }

  .modalHeader {
    font-size: 1.5rem;
    line-height: 2rem;
  }

  .modalTextInfo {
    font-size: 1.1rem;
  }

  .modalBold,
  .modalText {
    font-size: 1rem;
    line-height: 1.5rem;
  }
}

.navIcon {
  width: 60%;
  height: 100%;
  position: relative;
  transform: rotate(0deg);
  transition: 0.5s ease-in-out;
  cursor: pointer;
  display: flex;
  justify-content: center;
}

.navIcon span {
  margin: 0 auto;
  display: block;
  position: absolute;
  height: 3px;
  width: 21px;
  border-radius: 2px;
  background: var(--white);
  opacity: 1;
  transform: rotate(0deg);
  transition: transform 0.25s ease-in-out;
}

.navIcon span:nth-child(1) {
  top: 12px;
}

.navIcon span:nth-child(2),
.navIcon span:nth-child(3) {
  top: 20px;
}

.navIcon span:nth-child(4) {
  top: 28px;
}

.navIcon.open span:nth-child(1) {
  top: 20px;
  width: 0;
  left: 50%;
}

.navIcon.open span:nth-child(2) {
  transform: rotate(45deg);
}

.navIcon.open span:nth-child(3) {
  transform: rotate(-45deg);
}

.navIcon.open span:nth-child(4) {
  top: 20px;
  width: 0;
  left: 50%;
}

@media screen and (max-width: 660px) {
  .modal__email {
    max-width: 540px;
  }
}

@media screen and (max-width: 600px) {
  .modal {
    max-width: 361px;
    padding-top: 40px;
    padding-left: 17px;
    padding-right: 16px;
  }

  .modal__first {
    padding: 52px 15px 38px;
  }

  .modalTextInfo {
    font-size: 1.125rem;
  }

  .modalBold,
  .modalText {
    font-size: 0.875rem;
  }

  .modalCloseIcon {
    right: 29px;
    top: 30px;
  }

  .modalHeader {
    margin-bottom: 13px;
  }

  .modalHeader__first {
    margin-bottom: 31px;
  }

  .modalTextInfo {
    line-height: 1.3;
    margin-bottom: 19px;
    padding-left: 30px;
    padding-right: 30px;
  }

  .modalBold,
  .modalText {
    line-height: 1.5;
  }

  .modalText {
    letter-spacing: -0.14px;
    margin-bottom: 6px;
    padding-left: 26px;
    padding-right: 26px;
  }

  .modalButton__first {
    margin-top: 13px;
  }
}

@media screen and (max-width: 550px) {
  .modal__email {
    max-width: 361px;
    padding-top: 40px;
    padding-bottom: 15px;
  }

  .modalTextInfo__email {
    padding-left: 15px;
    padding-right: 15px;
  }

  .modalText__email {
    padding-left: 15px;
    padding-right: 15px;
  }
}

@media screen and (max-width: 480px) {
  .body {
    padding-top: 0px;
  }
}

@media screen and (max-width: 460px) {
  .modal__sent {
    max-width: 361px;
    padding-bottom: 5px;
    border-radius: 1.25rem;
  }
}

@media screen and (max-width: 370px) {
  .modal {
    max-width: 320px;
  }
}

@media screen and (max-width: 364px) {
  .modal__email {
    max-width: 320px;
  }
}

@font-face {
  font-family: Gilroy;
  font-style: normal;
  font-weight: 100;
  src: url("./assets/fonts/Gilroy-Thin.ttf");
}

@font-face {
  font-family: Gilroy;
  font-style: italic;
  font-weight: 100;
  src: url("./assets/fonts/Gilroy-ThinItalic.ttf");
}

@font-face {
  font-family: Gilroy;
  font-style: normal;
  font-weight: 200;
  src: url("./assets/fonts/Gilroy-UltraLight.ttf");
}

@font-face {
  font-family: Gilroy;
  font-style: italic;
  font-weight: 200;
  src: url("./assets/fonts/Gilroy-UltraLightItalic.ttf");
}

@font-face {
  font-family: Gilroy;
  font-style: normal;
  font-weight: 300;
  src: url("./assets/fonts/Gilroy-Light.ttf");
}

@font-face {
  font-family: Gilroy;
  font-style: italic;
  font-weight: 300;
  src: url("./assets/fonts/Gilroy-LightItalic.ttf");
}

@font-face {
  font-family: Gilroy;
  font-style: normal;
  font-weight: 400;
  src: url("./assets/fonts/Gilroy-Regular.ttf");
}

@font-face {
  font-family: Gilroy;
  font-style: italic;
  font-weight: 400;
  src: url("./assets/fonts/Gilroy-RegularItalic.ttf");
}

@font-face {
  font-family: Gilroy;
  font-style: normal;
  font-weight: 500;
  src: url("./assets/fonts/Gilroy-Medium.ttf");
}

@font-face {
  font-family: Gilroy;
  font-style: italic;
  font-weight: 500;
  src: url("./assets/fonts/Gilroy-MediumItalic.ttf");
}

@font-face {
  font-family: Gilroy;
  font-style: normal;
  font-weight: 600;
  src: url("./assets/fonts/Gilroy-Semibold.ttf");
}

@font-face {
  font-family: Gilroy;
  font-style: italic;
  font-weight: 600;
  src: url("./assets/fonts/Gilroy-SemiboldItalic.ttf");
}

@font-face {
  font-family: Gilroy;
  font-style: normal;
  font-weight: 700;
  src: url("./assets/fonts/Gilroy-Bold.ttf");
}

@font-face {
  font-family: Gilroy;
  font-style: italic;
  font-weight: 700;
  src: url("./assets/fonts/Gilroy-BoldItalic.ttf");
}

@font-face {
  font-family: Gilroy;
  font-style: normal;
  font-weight: 800;
  src: url("./assets/fonts/Gilroy-Extrabold.ttf");
}

@font-face {
  font-family: Gilroy;
  font-style: italic;
  font-weight: 800;
  src: url("./assets/fonts/Gilroy-ExtraboldItalic.ttf");
}

@font-face {
  font-family: Gilroy;
  font-style: normal;
  font-weight: 900;
  src: url("./assets/fonts/Gilroy-Heavy.ttf");
}

@font-face {
  font-family: Gilroy;
  font-style: italic;
  font-weight: 900;
  src: url("./assets/fonts/Gilroy-HeavyItalic.ttf");
}
