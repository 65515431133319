.images.carousel {
  position: relative;
  max-width: 810px;
  width: 100%;
  margin-inline: auto;
}

.carouselContainer {
  margin-inline: auto;
  padding-left: 20px;
  padding-right: 20px;
}

.contentContainer {
  display: flex;
  margin-bottom: 53px;
  gap: 120px;
}

.slide {
  flex: 0 0 100%;
  text-align: center;
  min-width: 0;
  transition: opacity 0.5s ease-in-out;
  cursor: pointer;
}

.slideImg {
  display: block;
  width: 100%;
  object-fit: cover;
  border-radius: 25px;
}

.slideText {
  display: inline-block;
  margin-top: 37px;
  font-size: 36px;
  font-weight: 700;
  line-height: 45px;
  color: var(--primary-text);
}

.notSnapped {
  opacity: 0.5;
}

.prevBtn,
.nextBtn {
  padding: 0;
  background-color: transparent;
  border: 0;
  border-radius: 50%;
  width: 70px;
  height: 70px;
  background-color: var(--white);
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 186px;
  cursor: pointer;
  transition: background-color 0.3s;
  z-index: 1000;

  svg {
    fill: #487ffa;
    transition: fill 0.3s;
  }

  img {
    display: block;
  }

  &:hover,
  &:active {
    background-color: #487ffa;

    svg {
      fill: var(--white);
    }
  }
}

.prevBtn {
  right: calc(100% + 2%);
  padding-right: 3px;
}

.nextBtn {
  left: calc(100% + 2%);
  padding-left: 3px;
}

.disabledBtn.prevBtn,
.disabledBtn.nextBtn {
  background-color: #cbcfd9;
  cursor: not-allowed;
}

.disabledBtn svg {
  fill: var(--white);
}

.carouselIndicator {
  display: flex;
  justify-content: center;
  gap: 14px;
  max-width: 320px;
  margin-inline: auto;
}

.carouselIndicatorItem {
  padding: 0;
  width: 10px;
  height: 10px;
  background-color: #e7f5ff;
  border: 1px solid var(--main-blue);
  border-radius: 50%;
  cursor: pointer;
}

.carouselIndicator .carouselIndicatorActive {
  background-color: var(--main-blue);
}

.mobile {
  position: relative;
  width: 351px;

  .carouselContainer {
    width: 100%;
    padding-left: 0;
    padding-right: 0;
    padding-top: 45px;
    overflow: hidden;
  }

  .carouselContentContainer {
    display: flex;
    margin-bottom: 25px;
  }

  .slide {
    flex: 0 0 351px;
    min-width: 0;
    display: flex;
    justify-content: center;
    transition: opacity 0.5s ease-in-out;
  }

  .prevBtn,
  .nextBtn {
    width: 40px;
    height: 40px;
    top: 186px;
    cursor: pointer;
    transition: background-color 0.3s;
    z-index: 1000;

    svg {
      width: 15px;
    }

    &:hover,
    &:active {
      background-color: #487ffa;

      svg {
        fill: var(--white);
      }
    }
  }

  .prevBtn {
    position: absolute;
    left: -10px;
    top: 47.5%;
  }

  .nextBtn {
    position: absolute;
    left: 320px;
    top: 47.5%;
  }
}

@media screen and (max-width: 1200px) {
  .images {
    .contentContainer {
      gap: 80px;
    }

    .prevBtn {
      right: calc(100% - 2%);
    }

    .nextBtn {
      left: calc(100% - 2%);
    }
  }
}

@media screen and (max-width: 1024px) {
  .images.carousel {
    max-width: 650px;
  }

  .images {
    .contentContainer {
      margin-bottom: 23px;
    }

    .slideText {
      margin-top: 30px;
      font-size: 28px;
      line-height: 35px;
    }

    .prevBtn,
    .nextBtn {
      width: 50px;
      height: 50px;
      top: 35%;
    }

    .prevBtn {
      right: calc(100% - 1%);
    }

    .nextBtn {
      left: calc(100% - 1%);
    }
  }
}

@media screen and (max-width: 767px) {
  .images {
    .carouselContainer {
      padding-left: 10px;
      padding-right: 10px;
    }

    .prevBtn,
    .nextBtn {
      width: 40px;
      height: 40px;

      svg {
        width: 20px;
        height: 20px;
      }
    }

    .prevBtn {
      right: calc(100% - 5%);
    }

    .nextBtn {
      left: calc(100% - 5%);
    }
  }
}

@media screen and (max-width: 670px) {
  .images {
    .prevBtn,
    .nextBtn {
      width: 40px;
      height: 40px;
      top: calc(50% - 70px);

      svg {
        width: 20px;
        height: 20px;
      }
    }

    .prevBtn {
      right: calc(100% - 50px);
    }

    .nextBtn {
      left: calc(100% - 50px);
    }
  }
}
